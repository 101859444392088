import { LogoWeb } from '../../assets/images'

const Logo = () => {
  return (
    <div className="inset-x-0 inset-y-0">
      <div className="flex w-full justify-center items-center py-3 logonx">
        <img alt="rtp slot terpercaya" src={LogoWeb} />
      </div>
    </div>
  );
};

export default Logo;