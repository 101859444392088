import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchRTPsData, fetchJamGacorsData } from '../store/actions/rtpAction';
import Logo from '../components/logos/LogoNX';
import Menu from '../components/Menu';
import Button from '../components/buttons/ButtonNX';
import PragmaticCard from '../components/games/PragmaticCard';
import PGSoftCard from '../components/games/PGSoftCard';
import HabaneroCard from '../components/games/HabaneroCard';
import JokerGamingCard from '../components/games/JokerGamingCard';
import SpadeGamingCard from '../components/games/SpadeGamingCard';
import JiliGamingCard from '../components/games/JiliGamingCard';
import FastSpinCard from '../components/games/FastSpinCard';
import PlayStarCard from '../components/games/PlayStarCard';
import Live22Card from '../components/games/Live22Card';
import AisGamingCard from '../components/games/AisGamingCard';
import CQ9Card from '../components/games/CQ9Card';
import MicroGamingCard from '../components/games/MicroGamingCard';
import TopTrendGamingCard from '../components/games/TopTrendGamingCard';

const Home = () => {
  const dispatch = useDispatch();
  const { rtps, jamGacors, isLoading } = useSelector((state) => state.rtpReducer);
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchRTPsData());
    dispatch(fetchJamGacorsData());
    window.scrollTo({
      top: 0
    });
  }, []);

  return (
    <>
      <Logo />
      <Menu />
      <Button />
      <div className="flex w-full min-h-screen">
        <div className="flex flex-col items-start justify-start px-4 pt-2 pb-8 mx-auto sm:px-6 lg:px-8">
          {(() => {
            switch (location.pathname) {
              case '/pragmatic':
                return <PragmaticCard rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              case '/pg-soft':
                return <PGSoftCard rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              case '/habanero':
                return <HabaneroCard rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              case '/joker-gaming':
                return <JokerGamingCard rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              case '/spade-gaming':
                return <SpadeGamingCard rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              case '/jili-gaming':
                return <JiliGamingCard rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              case '/fast-spin':
                return <FastSpinCard rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              case '/play-star':
                return <PlayStarCard rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              case '/live-22':
                return <Live22Card rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              case '/ais-gaming':
                return <AisGamingCard rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              case '/cq9':
                return <CQ9Card rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              case '/micro-gaming':
                return <MicroGamingCard rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              case '/top-trend-gaming':
                return <TopTrendGamingCard rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
              default:
                return <PragmaticCard rtps={rtps} jamGacors={jamGacors} isLoading={isLoading} />
            }
          })()}
        </div>
      </div>
    </>
  );
};

export default Home;